import React, { useEffect, useState } from 'react';
import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import { Pallet } from '../../store/api/apiTypes';
import CustomModal from '../../components/CustomModal';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import { Col, Input, InputProps, message, Row, Typography } from 'antd';
import { useActions, useArrowNavScope, usePrevious, useScanner, useShortcutScope } from '../../hooks';
import {
    changeResupplyId as changeResupplyIdAction,
    getPalletChangeResupplyIdState,
    detailsByReference as palletByRef,
    detailsByBarcode as palletByBarcode,
    getPalletDetailsStateByReference,
    getPalletDetailsStateByBarcode,
} from '../../store/actions/pallets';
import { useSelector } from 'react-redux';
import { details as resupplyOrderDetails, getResupplyOrderDetailsStateById } from '../../store/actions/resupplyOrders';
import { useParams } from 'react-router-dom';
import { WarningOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import SuccessMessage from '../../components/SuccessMessage';

interface ChangeReservationScanModalProps {
    pallet?: Pallet;
}

const shortcutScope = 'ChangeReservationScanModal';

const ChangeReservationScanModal = NiceModal.create<ChangeReservationScanModalProps>(({ pallet }) => {
    const { resupplyOrderId } = useParams<{ resupplyOrderId: string }>();
    const modal = useModal();
    const [palletReference, setPalletReference] = useState<Pallet['reference']>();
    const [palletBarcode, setPalletBarcode] = useState<Pallet['barcode']>();
    const [errorKey, setErrorKey] = useState<keyof typeof errorMessage>();
    const [step, setStep] = useState<'scan' | 'error' | 'confirm' | 'success'>('scan');
    const [changeResupplyId, fetchResupplyOrder, fetchPalletByRef, fetchPalletByBarcode] = useActions([
        changeResupplyIdAction.trigger,
        resupplyOrderDetails.trigger,
        palletByRef.trigger,
        palletByBarcode.trigger,
    ]);
    const changeResupplyIdState = useSelector(getPalletChangeResupplyIdState);
    const resupplyOrderState = useSelector(getResupplyOrderDetailsStateById(parseInt(resupplyOrderId, 10)));
    const palletDetailsStateByReference = useSelector(getPalletDetailsStateByReference(palletReference));
    const palletDetailsStateByBarcode = useSelector(getPalletDetailsStateByBarcode(palletBarcode));
    const previous = usePrevious({
        changeResupplyIdState,
        resupplyOrderState,
        palletDetailsStateByReference,
        palletDetailsStateByBarcode,
    });
    const { hide, visible } = modal;
    useShortcutScope(shortcutScope);
    useArrowNavScope(shortcutScope);

    const errorMessage: { [key: string]: string } = {
        palletReserved: 'Cette palette est déjà réservée pour une autre opération',
        notaMassPlace: "L'emplacement de la palette scannée n'est pas une emplacement de masse",
        placeBlocked: "L'emplacement de la palette scannée est bloqué",
        differentParcels: 'La palette scannée ne correspond pas à la référence résérvée.',
        differentNumberOfPackages: 'La palette scannée ne possède pas la quantité demandée.',
        alreadyReservedForThisDirect: 'Cette palette est déjà réservée pour cette opération',
        palletNotConform: "La palette scannée n'est pas conforme",
    };

    useEffect(() => {
        if (previous?.palletDetailsStateByReference.loading && !palletDetailsStateByReference.loading) {
            if (palletDetailsStateByReference.error) {
                message.error('Erreur lors de la récupération de la palette');
            } else if (palletDetailsStateByReference.data) {
                if (pallet?.parcel?.id !== palletDetailsStateByReference.data?.parcel?.id) {
                    setErrorKey('differentParcels');
                    setStep('error');
                } else if (pallet?.quantity !== palletDetailsStateByReference.data?.quantity) {
                    setErrorKey('differentNumberOfPackages');
                    setStep('error');
                } else {
                    setStep('confirm');
                }
            }
        }
    }, [
        pallet?.parcel?.id,
        pallet?.quantity,
        palletDetailsStateByReference.data,
        palletDetailsStateByReference.error,
        palletDetailsStateByReference.loading,
        previous?.palletDetailsStateByReference.loading,
    ]);

    useEffect(() => {
        if (previous?.palletDetailsStateByBarcode.loading && !palletDetailsStateByBarcode.loading) {
            if (palletDetailsStateByBarcode.error) {
                message.error('Erreur lors de la récupération de la palette');
            } else if (palletDetailsStateByBarcode.data) {
                if (pallet?.parcel?.id !== palletDetailsStateByBarcode.data?.parcel?.id) {
                    setErrorKey('differentParcels');
                    setStep('error');
                } else if (pallet?.quantity !== palletDetailsStateByBarcode.data?.quantity) {
                    setErrorKey('differentNumberOfPackages');
                    setStep('error');
                } else {
                    setStep('confirm');
                }
            }
        }
    }, [
        pallet?.parcel?.id,
        pallet?.quantity,
        palletDetailsStateByBarcode,
        palletDetailsStateByBarcode.data,
        palletDetailsStateByBarcode.error,
        palletDetailsStateByBarcode.loading,
        previous?.palletDetailsStateByBarcode.loading,
    ]);

    useEffect(() => {
        if (previous?.changeResupplyIdState.loading && !changeResupplyIdState.loading) {
            if (changeResupplyIdState.error) {
                message.error(errorMessage[changeResupplyIdState.error.data.changeResupplyIdError]);
            } else {
                fetchResupplyOrder({ resupplyOrderId });
            }
        }
    }, [
        changeResupplyIdState.error,
        changeResupplyIdState.loading,
        errorMessage,
        fetchResupplyOrder,
        previous?.changeResupplyIdState.loading,
        resupplyOrderId,
    ]);

    useEffect(() => {
        if (previous?.resupplyOrderState.loading && !resupplyOrderState.loading) {
            if (resupplyOrderState.error) {
                message.error('Erreur lors de la récupération du réapprovisionnement');
            } else {
                setStep('success');
            }
        }
    }, [hide, previous, resupplyOrderState.error, resupplyOrderState.loading]);

    useEffect(() => {
        let timeout: number;
        if (step === 'success') {
            timeout = window.setTimeout(() => {
                hide();
            }, 1000);
        }
        return () => {
            if (timeout) {
                window.clearTimeout(timeout);
            }
        };
    }, [hide, step]);

    useScanner(
        shortcutScope,
        (barCode) => {
            if (barCode.data && pallet) {
                setPalletBarcode(barCode.data);
                fetchPalletByBarcode({
                    barcode: barCode.data,
                });
            }
        },
        {
            disable: !visible,
        }
    );
    const onValid = () => {
        fetchPalletByRef({
            reference: palletReference,
        });
    };

    const onConfirm = () => {
        changeResupplyId({
            palletId: pallet?.id,
            barcode: palletBarcode,
            reference: palletReference,
        });
    };

    const onChange: InputProps['onChange'] = (e) => {
        setPalletReference(e.target.value.toUpperCase());
    };

    const onBack = () => {
        if (palletReference) {
            setPalletReference(undefined);
        }
        setStep('scan');
    };

    const contentMap = {
        scan: (
            <ArrowNavItem scope={shortcutScope}>
                <Input placeholder="Flasher la palette" onChange={onChange} autoFocus />
            </ArrowNavItem>
        ),
        error: errorKey && (
            <Row justify="center">
                <Col xs={16} className="text-center">
                    <Typography.Title level={4} style={{ fontSize: '35px' }} type="warning">
                        <WarningOutlined />
                    </Typography.Title>
                    <Text strong={true}>{errorMessage[errorKey]}</Text>
                </Col>
            </Row>
        ),
        confirm: (
            <Row justify="center">
                <Col xs={16} className="text-center">
                    <Text strong>Êtes-vous sur de vouloir modifier la palette résérvée par celle-ci ?</Text>
                </Col>
            </Row>
        ),
        success: (
            <SuccessMessage message={<Text strong>Modification de la palette à prélevée réalisée avec succès</Text>} />
        ),
    };

    const content = contentMap[step];

    const footerMap = {
        scan: (
            <>
                <ArrowNavItem scope={shortcutScope}>
                    <ButtonWithShortcut
                        type="primary"
                        shortcut="enter"
                        shortcutScope={shortcutScope}
                        onClick={onValid}
                        disabled={!palletReference || !pallet}
                        loading={changeResupplyIdState.loading}
                    >
                        Valider
                    </ButtonWithShortcut>
                </ArrowNavItem>
                <ArrowNavItem scope={shortcutScope}>
                    <ButtonWithShortcut
                        type="primary"
                        shortcut="esc"
                        shortcutScope={shortcutScope}
                        onClick={hide}
                        loading={changeResupplyIdState.loading}
                        ghost
                    >
                        Annuler
                    </ButtonWithShortcut>
                </ArrowNavItem>
            </>
        ),
        error: (
            <>
                <ArrowNavItem scope={shortcutScope}>
                    <ButtonWithShortcut type="primary" shortcut="esc" shortcutScope={shortcutScope} onClick={onBack}>
                        Retour
                    </ButtonWithShortcut>
                </ArrowNavItem>
            </>
        ),
        confirm: (
            <>
                <ArrowNavItem scope={shortcutScope}>
                    <ButtonWithShortcut
                        shortcut="enter"
                        shortcutOptions={{ enableOnTags: ['INPUT'] }}
                        shortcutScope={shortcutScope}
                        type="primary"
                        onClick={onConfirm}
                        loading={changeResupplyIdState.loading}
                    >
                        Confirmer
                    </ButtonWithShortcut>
                </ArrowNavItem>
                <ArrowNavItem scope={shortcutScope}>
                    <ButtonWithShortcut
                        shortcut={'esc'}
                        shortcutOptions={{ enableOnTags: ['INPUT'] }}
                        shortcutScope={shortcutScope}
                        type="primary"
                        onClick={onBack}
                        loading={changeResupplyIdState.loading}
                        ghost
                    >
                        Annuler
                    </ButtonWithShortcut>
                </ArrowNavItem>
            </>
        ),
        success: null,
    };
    const footer = footerMap[step];

    return (
        <CustomModal
            {...antdModal(modal)}
            footer={footer}
            title={step === 'scan' && 'Modifier la palette reservée'}
            altTitle
            width={368}
        >
            {content}
        </CustomModal>
    );
});

export default ChangeReservationScanModal;
